// source: flow/flow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.flow.AgentEvent', null, global);
goog.exportSymbol('proto.flow.AgentEvent.NotificationCase', null, global);
goog.exportSymbol('proto.flow.AgentEventType', null, global);
goog.exportSymbol('proto.flow.AgentEventUnknown', null, global);
goog.exportSymbol('proto.flow.CiliumEventType', null, global);
goog.exportSymbol('proto.flow.DNS', null, global);
goog.exportSymbol('proto.flow.DebugCapturePoint', null, global);
goog.exportSymbol('proto.flow.DebugEvent', null, global);
goog.exportSymbol('proto.flow.DebugEventType', null, global);
goog.exportSymbol('proto.flow.DropReason', null, global);
goog.exportSymbol('proto.flow.Endpoint', null, global);
goog.exportSymbol('proto.flow.EndpointRegenNotification', null, global);
goog.exportSymbol('proto.flow.EndpointUpdateNotification', null, global);
goog.exportSymbol('proto.flow.Ethernet', null, global);
goog.exportSymbol('proto.flow.EventType', null, global);
goog.exportSymbol('proto.flow.EventTypeFilter', null, global);
goog.exportSymbol('proto.flow.Flow', null, global);
goog.exportSymbol('proto.flow.FlowFilter', null, global);
goog.exportSymbol('proto.flow.FlowType', null, global);
goog.exportSymbol('proto.flow.HTTP', null, global);
goog.exportSymbol('proto.flow.HTTPHeader', null, global);
goog.exportSymbol('proto.flow.ICMPv4', null, global);
goog.exportSymbol('proto.flow.ICMPv6', null, global);
goog.exportSymbol('proto.flow.IP', null, global);
goog.exportSymbol('proto.flow.IPCacheNotification', null, global);
goog.exportSymbol('proto.flow.IPVersion', null, global);
goog.exportSymbol('proto.flow.Kafka', null, global);
goog.exportSymbol('proto.flow.L7FlowType', null, global);
goog.exportSymbol('proto.flow.Layer4', null, global);
goog.exportSymbol('proto.flow.Layer4.ProtocolCase', null, global);
goog.exportSymbol('proto.flow.Layer7', null, global);
goog.exportSymbol('proto.flow.Layer7.RecordCase', null, global);
goog.exportSymbol('proto.flow.LostEvent', null, global);
goog.exportSymbol('proto.flow.LostEventSource', null, global);
goog.exportSymbol('proto.flow.NetworkInterface', null, global);
goog.exportSymbol('proto.flow.PolicyUpdateNotification', null, global);
goog.exportSymbol('proto.flow.SCTP', null, global);
goog.exportSymbol('proto.flow.Service', null, global);
goog.exportSymbol('proto.flow.ServiceDeleteNotification', null, global);
goog.exportSymbol('proto.flow.ServiceUpsertNotification', null, global);
goog.exportSymbol('proto.flow.ServiceUpsertNotificationAddr', null, global);
goog.exportSymbol('proto.flow.SocketTranslationPoint', null, global);
goog.exportSymbol('proto.flow.TCP', null, global);
goog.exportSymbol('proto.flow.TCPFlags', null, global);
goog.exportSymbol('proto.flow.TimeNotification', null, global);
goog.exportSymbol('proto.flow.TraceContext', null, global);
goog.exportSymbol('proto.flow.TraceObservationPoint', null, global);
goog.exportSymbol('proto.flow.TraceParent', null, global);
goog.exportSymbol('proto.flow.TrafficDirection', null, global);
goog.exportSymbol('proto.flow.UDP', null, global);
goog.exportSymbol('proto.flow.Verdict', null, global);
goog.exportSymbol('proto.flow.Workload', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Flow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.flow.Flow.repeatedFields_, null);
};
goog.inherits(proto.flow.Flow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Flow.displayName = 'proto.flow.Flow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Layer4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.flow.Layer4.oneofGroups_);
};
goog.inherits(proto.flow.Layer4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Layer4.displayName = 'proto.flow.Layer4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Layer7 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.flow.Layer7.oneofGroups_);
};
goog.inherits(proto.flow.Layer7, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Layer7.displayName = 'proto.flow.Layer7';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.TraceContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.TraceContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.TraceContext.displayName = 'proto.flow.TraceContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.TraceParent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.TraceParent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.TraceParent.displayName = 'proto.flow.TraceParent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Endpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.Endpoint.repeatedFields_, null);
};
goog.inherits(proto.flow.Endpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Endpoint.displayName = 'proto.flow.Endpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Workload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.Workload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Workload.displayName = 'proto.flow.Workload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.TCP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.TCP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.TCP.displayName = 'proto.flow.TCP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.IP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.IP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.IP.displayName = 'proto.flow.IP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Ethernet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.Ethernet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Ethernet.displayName = 'proto.flow.Ethernet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.TCPFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.TCPFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.TCPFlags.displayName = 'proto.flow.TCPFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.UDP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.UDP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.UDP.displayName = 'proto.flow.UDP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.SCTP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.SCTP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.SCTP.displayName = 'proto.flow.SCTP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.ICMPv4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.ICMPv4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.ICMPv4.displayName = 'proto.flow.ICMPv4';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.ICMPv6 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.ICMPv6, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.ICMPv6.displayName = 'proto.flow.ICMPv6';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.EventTypeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.EventTypeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.EventTypeFilter.displayName = 'proto.flow.EventTypeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.CiliumEventType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.CiliumEventType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.CiliumEventType.displayName = 'proto.flow.CiliumEventType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.FlowFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.FlowFilter.repeatedFields_, null);
};
goog.inherits(proto.flow.FlowFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.FlowFilter.displayName = 'proto.flow.FlowFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.DNS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.DNS.repeatedFields_, null);
};
goog.inherits(proto.flow.DNS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.DNS.displayName = 'proto.flow.DNS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.HTTPHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.HTTPHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.HTTPHeader.displayName = 'proto.flow.HTTPHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.HTTP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.HTTP.repeatedFields_, null);
};
goog.inherits(proto.flow.HTTP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.HTTP.displayName = 'proto.flow.HTTP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Kafka = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.Kafka, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Kafka.displayName = 'proto.flow.Kafka';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.Service.displayName = 'proto.flow.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.LostEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.LostEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.LostEvent.displayName = 'proto.flow.LostEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.AgentEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.flow.AgentEvent.oneofGroups_);
};
goog.inherits(proto.flow.AgentEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.AgentEvent.displayName = 'proto.flow.AgentEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.AgentEventUnknown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.AgentEventUnknown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.AgentEventUnknown.displayName = 'proto.flow.AgentEventUnknown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.TimeNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.TimeNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.TimeNotification.displayName = 'proto.flow.TimeNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.PolicyUpdateNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.PolicyUpdateNotification.repeatedFields_, null);
};
goog.inherits(proto.flow.PolicyUpdateNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.PolicyUpdateNotification.displayName = 'proto.flow.PolicyUpdateNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.EndpointRegenNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.EndpointRegenNotification.repeatedFields_, null);
};
goog.inherits(proto.flow.EndpointRegenNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.EndpointRegenNotification.displayName = 'proto.flow.EndpointRegenNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.EndpointUpdateNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.EndpointUpdateNotification.repeatedFields_, null);
};
goog.inherits(proto.flow.EndpointUpdateNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.EndpointUpdateNotification.displayName = 'proto.flow.EndpointUpdateNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.IPCacheNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.IPCacheNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.IPCacheNotification.displayName = 'proto.flow.IPCacheNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.ServiceUpsertNotificationAddr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.ServiceUpsertNotificationAddr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.ServiceUpsertNotificationAddr.displayName = 'proto.flow.ServiceUpsertNotificationAddr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.ServiceUpsertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.ServiceUpsertNotification.repeatedFields_, null);
};
goog.inherits(proto.flow.ServiceUpsertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.ServiceUpsertNotification.displayName = 'proto.flow.ServiceUpsertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.ServiceDeleteNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.ServiceDeleteNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.ServiceDeleteNotification.displayName = 'proto.flow.ServiceDeleteNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.NetworkInterface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.NetworkInterface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.NetworkInterface.displayName = 'proto.flow.NetworkInterface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.DebugEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.DebugEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.DebugEvent.displayName = 'proto.flow.DebugEvent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.Flow.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Flow.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Flow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Flow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Flow.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    verdict: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dropReason: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ethernet: (f = msg.getEthernet()) && proto.flow.Ethernet.toObject(includeInstance, f),
    ip: (f = msg.getIp()) && proto.flow.IP.toObject(includeInstance, f),
    l4: (f = msg.getL4()) && proto.flow.Layer4.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.flow.Endpoint.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.flow.Endpoint.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 10, 0),
    nodeName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sourceNamesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    destinationNamesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    l7: (f = msg.getL7()) && proto.flow.Layer7.toObject(includeInstance, f),
    reply: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    eventType: (f = msg.getEventType()) && proto.flow.CiliumEventType.toObject(includeInstance, f),
    sourceService: (f = msg.getSourceService()) && proto.flow.Service.toObject(includeInstance, f),
    destinationService: (f = msg.getDestinationService()) && proto.flow.Service.toObject(includeInstance, f),
    trafficDirection: jspb.Message.getFieldWithDefault(msg, 22, 0),
    policyMatchType: jspb.Message.getFieldWithDefault(msg, 23, 0),
    traceObservationPoint: jspb.Message.getFieldWithDefault(msg, 24, 0),
    dropReasonDesc: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isReply: (f = msg.getIsReply()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    debugCapturePoint: jspb.Message.getFieldWithDefault(msg, 27, 0),
    pb_interface: (f = msg.getInterface()) && proto.flow.NetworkInterface.toObject(includeInstance, f),
    proxyPort: jspb.Message.getFieldWithDefault(msg, 29, 0),
    traceContext: (f = msg.getTraceContext()) && proto.flow.TraceContext.toObject(includeInstance, f),
    sockXlatePoint: jspb.Message.getFieldWithDefault(msg, 31, 0),
    socketCookie: jspb.Message.getFieldWithDefault(msg, 32, 0),
    cgroupId: jspb.Message.getFieldWithDefault(msg, 33, 0),
    summary: jspb.Message.getFieldWithDefault(msg, 100000, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Flow}
 */
proto.flow.Flow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Flow;
  return proto.flow.Flow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Flow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Flow}
 */
proto.flow.Flow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {!proto.flow.Verdict} */ (reader.readEnum());
      msg.setVerdict(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDropReason(value);
      break;
    case 4:
      var value = new proto.flow.Ethernet;
      reader.readMessage(value,proto.flow.Ethernet.deserializeBinaryFromReader);
      msg.setEthernet(value);
      break;
    case 5:
      var value = new proto.flow.IP;
      reader.readMessage(value,proto.flow.IP.deserializeBinaryFromReader);
      msg.setIp(value);
      break;
    case 6:
      var value = new proto.flow.Layer4;
      reader.readMessage(value,proto.flow.Layer4.deserializeBinaryFromReader);
      msg.setL4(value);
      break;
    case 8:
      var value = new proto.flow.Endpoint;
      reader.readMessage(value,proto.flow.Endpoint.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 9:
      var value = new proto.flow.Endpoint;
      reader.readMessage(value,proto.flow.Endpoint.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 10:
      var value = /** @type {!proto.flow.FlowType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceNames(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationNames(value);
      break;
    case 15:
      var value = new proto.flow.Layer7;
      reader.readMessage(value,proto.flow.Layer7.deserializeBinaryFromReader);
      msg.setL7(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReply(value);
      break;
    case 19:
      var value = new proto.flow.CiliumEventType;
      reader.readMessage(value,proto.flow.CiliumEventType.deserializeBinaryFromReader);
      msg.setEventType(value);
      break;
    case 20:
      var value = new proto.flow.Service;
      reader.readMessage(value,proto.flow.Service.deserializeBinaryFromReader);
      msg.setSourceService(value);
      break;
    case 21:
      var value = new proto.flow.Service;
      reader.readMessage(value,proto.flow.Service.deserializeBinaryFromReader);
      msg.setDestinationService(value);
      break;
    case 22:
      var value = /** @type {!proto.flow.TrafficDirection} */ (reader.readEnum());
      msg.setTrafficDirection(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPolicyMatchType(value);
      break;
    case 24:
      var value = /** @type {!proto.flow.TraceObservationPoint} */ (reader.readEnum());
      msg.setTraceObservationPoint(value);
      break;
    case 25:
      var value = /** @type {!proto.flow.DropReason} */ (reader.readEnum());
      msg.setDropReasonDesc(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsReply(value);
      break;
    case 27:
      var value = /** @type {!proto.flow.DebugCapturePoint} */ (reader.readEnum());
      msg.setDebugCapturePoint(value);
      break;
    case 28:
      var value = new proto.flow.NetworkInterface;
      reader.readMessage(value,proto.flow.NetworkInterface.deserializeBinaryFromReader);
      msg.setInterface(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProxyPort(value);
      break;
    case 30:
      var value = new proto.flow.TraceContext;
      reader.readMessage(value,proto.flow.TraceContext.deserializeBinaryFromReader);
      msg.setTraceContext(value);
      break;
    case 31:
      var value = /** @type {!proto.flow.SocketTranslationPoint} */ (reader.readEnum());
      msg.setSockXlatePoint(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSocketCookie(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCgroupId(value);
      break;
    case 100000:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Flow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Flow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Flow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Flow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVerdict();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDropReason();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEthernet();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.flow.Ethernet.serializeBinaryToWriter
    );
  }
  f = message.getIp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.flow.IP.serializeBinaryToWriter
    );
  }
  f = message.getL4();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.flow.Layer4.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.flow.Endpoint.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.flow.Endpoint.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSourceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getDestinationNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getL7();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.flow.Layer7.serializeBinaryToWriter
    );
  }
  f = message.getReply();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getEventType();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.flow.CiliumEventType.serializeBinaryToWriter
    );
  }
  f = message.getSourceService();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.flow.Service.serializeBinaryToWriter
    );
  }
  f = message.getDestinationService();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.flow.Service.serializeBinaryToWriter
    );
  }
  f = message.getTrafficDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getPolicyMatchType();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getTraceObservationPoint();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getDropReasonDesc();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getIsReply();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDebugCapturePoint();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getInterface();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.flow.NetworkInterface.serializeBinaryToWriter
    );
  }
  f = message.getProxyPort();
  if (f !== 0) {
    writer.writeUint32(
      29,
      f
    );
  }
  f = message.getTraceContext();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.flow.TraceContext.serializeBinaryToWriter
    );
  }
  f = message.getSockXlatePoint();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getSocketCookie();
  if (f !== 0) {
    writer.writeUint64(
      32,
      f
    );
  }
  f = message.getCgroupId();
  if (f !== 0) {
    writer.writeUint64(
      33,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      100000,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.flow.Flow.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Verdict verdict = 2;
 * @return {!proto.flow.Verdict}
 */
proto.flow.Flow.prototype.getVerdict = function() {
  return /** @type {!proto.flow.Verdict} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flow.Verdict} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setVerdict = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 drop_reason = 3;
 * @return {number}
 */
proto.flow.Flow.prototype.getDropReason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setDropReason = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Ethernet ethernet = 4;
 * @return {?proto.flow.Ethernet}
 */
proto.flow.Flow.prototype.getEthernet = function() {
  return /** @type{?proto.flow.Ethernet} */ (
    jspb.Message.getWrapperField(this, proto.flow.Ethernet, 4));
};


/**
 * @param {?proto.flow.Ethernet|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setEthernet = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearEthernet = function() {
  return this.setEthernet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasEthernet = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IP IP = 5;
 * @return {?proto.flow.IP}
 */
proto.flow.Flow.prototype.getIp = function() {
  return /** @type{?proto.flow.IP} */ (
    jspb.Message.getWrapperField(this, proto.flow.IP, 5));
};


/**
 * @param {?proto.flow.IP|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setIp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearIp = function() {
  return this.setIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasIp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Layer4 l4 = 6;
 * @return {?proto.flow.Layer4}
 */
proto.flow.Flow.prototype.getL4 = function() {
  return /** @type{?proto.flow.Layer4} */ (
    jspb.Message.getWrapperField(this, proto.flow.Layer4, 6));
};


/**
 * @param {?proto.flow.Layer4|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setL4 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearL4 = function() {
  return this.setL4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasL4 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Endpoint source = 8;
 * @return {?proto.flow.Endpoint}
 */
proto.flow.Flow.prototype.getSource = function() {
  return /** @type{?proto.flow.Endpoint} */ (
    jspb.Message.getWrapperField(this, proto.flow.Endpoint, 8));
};


/**
 * @param {?proto.flow.Endpoint|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasSource = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Endpoint destination = 9;
 * @return {?proto.flow.Endpoint}
 */
proto.flow.Flow.prototype.getDestination = function() {
  return /** @type{?proto.flow.Endpoint} */ (
    jspb.Message.getWrapperField(this, proto.flow.Endpoint, 9));
};


/**
 * @param {?proto.flow.Endpoint|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FlowType Type = 10;
 * @return {!proto.flow.FlowType}
 */
proto.flow.Flow.prototype.getType = function() {
  return /** @type {!proto.flow.FlowType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.flow.FlowType} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string node_name = 11;
 * @return {string}
 */
proto.flow.Flow.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string source_names = 13;
 * @return {!Array<string>}
 */
proto.flow.Flow.prototype.getSourceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setSourceNamesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.addSourceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearSourceNamesList = function() {
  return this.setSourceNamesList([]);
};


/**
 * repeated string destination_names = 14;
 * @return {!Array<string>}
 */
proto.flow.Flow.prototype.getDestinationNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setDestinationNamesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.addDestinationNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearDestinationNamesList = function() {
  return this.setDestinationNamesList([]);
};


/**
 * optional Layer7 l7 = 15;
 * @return {?proto.flow.Layer7}
 */
proto.flow.Flow.prototype.getL7 = function() {
  return /** @type{?proto.flow.Layer7} */ (
    jspb.Message.getWrapperField(this, proto.flow.Layer7, 15));
};


/**
 * @param {?proto.flow.Layer7|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setL7 = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearL7 = function() {
  return this.setL7(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasL7 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool reply = 16;
 * @return {boolean}
 */
proto.flow.Flow.prototype.getReply = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setReply = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional CiliumEventType event_type = 19;
 * @return {?proto.flow.CiliumEventType}
 */
proto.flow.Flow.prototype.getEventType = function() {
  return /** @type{?proto.flow.CiliumEventType} */ (
    jspb.Message.getWrapperField(this, proto.flow.CiliumEventType, 19));
};


/**
 * @param {?proto.flow.CiliumEventType|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setEventType = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearEventType = function() {
  return this.setEventType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasEventType = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Service source_service = 20;
 * @return {?proto.flow.Service}
 */
proto.flow.Flow.prototype.getSourceService = function() {
  return /** @type{?proto.flow.Service} */ (
    jspb.Message.getWrapperField(this, proto.flow.Service, 20));
};


/**
 * @param {?proto.flow.Service|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setSourceService = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearSourceService = function() {
  return this.setSourceService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasSourceService = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Service destination_service = 21;
 * @return {?proto.flow.Service}
 */
proto.flow.Flow.prototype.getDestinationService = function() {
  return /** @type{?proto.flow.Service} */ (
    jspb.Message.getWrapperField(this, proto.flow.Service, 21));
};


/**
 * @param {?proto.flow.Service|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setDestinationService = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearDestinationService = function() {
  return this.setDestinationService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasDestinationService = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TrafficDirection traffic_direction = 22;
 * @return {!proto.flow.TrafficDirection}
 */
proto.flow.Flow.prototype.getTrafficDirection = function() {
  return /** @type {!proto.flow.TrafficDirection} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.flow.TrafficDirection} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setTrafficDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional uint32 policy_match_type = 23;
 * @return {number}
 */
proto.flow.Flow.prototype.getPolicyMatchType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setPolicyMatchType = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional TraceObservationPoint trace_observation_point = 24;
 * @return {!proto.flow.TraceObservationPoint}
 */
proto.flow.Flow.prototype.getTraceObservationPoint = function() {
  return /** @type {!proto.flow.TraceObservationPoint} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.flow.TraceObservationPoint} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setTraceObservationPoint = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional DropReason drop_reason_desc = 25;
 * @return {!proto.flow.DropReason}
 */
proto.flow.Flow.prototype.getDropReasonDesc = function() {
  return /** @type {!proto.flow.DropReason} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.flow.DropReason} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setDropReasonDesc = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional google.protobuf.BoolValue is_reply = 26;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.flow.Flow.prototype.getIsReply = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 26));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setIsReply = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearIsReply = function() {
  return this.setIsReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasIsReply = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional DebugCapturePoint debug_capture_point = 27;
 * @return {!proto.flow.DebugCapturePoint}
 */
proto.flow.Flow.prototype.getDebugCapturePoint = function() {
  return /** @type {!proto.flow.DebugCapturePoint} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.flow.DebugCapturePoint} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setDebugCapturePoint = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional NetworkInterface interface = 28;
 * @return {?proto.flow.NetworkInterface}
 */
proto.flow.Flow.prototype.getInterface = function() {
  return /** @type{?proto.flow.NetworkInterface} */ (
    jspb.Message.getWrapperField(this, proto.flow.NetworkInterface, 28));
};


/**
 * @param {?proto.flow.NetworkInterface|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setInterface = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearInterface = function() {
  return this.setInterface(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasInterface = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional uint32 proxy_port = 29;
 * @return {number}
 */
proto.flow.Flow.prototype.getProxyPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setProxyPort = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional TraceContext trace_context = 30;
 * @return {?proto.flow.TraceContext}
 */
proto.flow.Flow.prototype.getTraceContext = function() {
  return /** @type{?proto.flow.TraceContext} */ (
    jspb.Message.getWrapperField(this, proto.flow.TraceContext, 30));
};


/**
 * @param {?proto.flow.TraceContext|undefined} value
 * @return {!proto.flow.Flow} returns this
*/
proto.flow.Flow.prototype.setTraceContext = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.clearTraceContext = function() {
  return this.setTraceContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Flow.prototype.hasTraceContext = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional SocketTranslationPoint sock_xlate_point = 31;
 * @return {!proto.flow.SocketTranslationPoint}
 */
proto.flow.Flow.prototype.getSockXlatePoint = function() {
  return /** @type {!proto.flow.SocketTranslationPoint} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.flow.SocketTranslationPoint} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setSockXlatePoint = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional uint64 socket_cookie = 32;
 * @return {number}
 */
proto.flow.Flow.prototype.getSocketCookie = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setSocketCookie = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional uint64 cgroup_id = 33;
 * @return {number}
 */
proto.flow.Flow.prototype.getCgroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setCgroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional string Summary = 100000;
 * @return {string}
 */
proto.flow.Flow.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100000, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Flow} returns this
 */
proto.flow.Flow.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 100000, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.flow.Layer4.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.flow.Layer4.ProtocolCase = {
  PROTOCOL_NOT_SET: 0,
  TCP: 1,
  UDP: 2,
  ICMPV4: 3,
  ICMPV6: 4,
  SCTP: 5
};

/**
 * @return {proto.flow.Layer4.ProtocolCase}
 */
proto.flow.Layer4.prototype.getProtocolCase = function() {
  return /** @type {proto.flow.Layer4.ProtocolCase} */(jspb.Message.computeOneofCase(this, proto.flow.Layer4.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Layer4.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Layer4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Layer4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Layer4.toObject = function(includeInstance, msg) {
  var f, obj = {
    tcp: (f = msg.getTcp()) && proto.flow.TCP.toObject(includeInstance, f),
    udp: (f = msg.getUdp()) && proto.flow.UDP.toObject(includeInstance, f),
    icmpv4: (f = msg.getIcmpv4()) && proto.flow.ICMPv4.toObject(includeInstance, f),
    icmpv6: (f = msg.getIcmpv6()) && proto.flow.ICMPv6.toObject(includeInstance, f),
    sctp: (f = msg.getSctp()) && proto.flow.SCTP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Layer4}
 */
proto.flow.Layer4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Layer4;
  return proto.flow.Layer4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Layer4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Layer4}
 */
proto.flow.Layer4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flow.TCP;
      reader.readMessage(value,proto.flow.TCP.deserializeBinaryFromReader);
      msg.setTcp(value);
      break;
    case 2:
      var value = new proto.flow.UDP;
      reader.readMessage(value,proto.flow.UDP.deserializeBinaryFromReader);
      msg.setUdp(value);
      break;
    case 3:
      var value = new proto.flow.ICMPv4;
      reader.readMessage(value,proto.flow.ICMPv4.deserializeBinaryFromReader);
      msg.setIcmpv4(value);
      break;
    case 4:
      var value = new proto.flow.ICMPv6;
      reader.readMessage(value,proto.flow.ICMPv6.deserializeBinaryFromReader);
      msg.setIcmpv6(value);
      break;
    case 5:
      var value = new proto.flow.SCTP;
      reader.readMessage(value,proto.flow.SCTP.deserializeBinaryFromReader);
      msg.setSctp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Layer4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Layer4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Layer4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Layer4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTcp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.flow.TCP.serializeBinaryToWriter
    );
  }
  f = message.getUdp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.flow.UDP.serializeBinaryToWriter
    );
  }
  f = message.getIcmpv4();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.flow.ICMPv4.serializeBinaryToWriter
    );
  }
  f = message.getIcmpv6();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.flow.ICMPv6.serializeBinaryToWriter
    );
  }
  f = message.getSctp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.flow.SCTP.serializeBinaryToWriter
    );
  }
};


/**
 * optional TCP TCP = 1;
 * @return {?proto.flow.TCP}
 */
proto.flow.Layer4.prototype.getTcp = function() {
  return /** @type{?proto.flow.TCP} */ (
    jspb.Message.getWrapperField(this, proto.flow.TCP, 1));
};


/**
 * @param {?proto.flow.TCP|undefined} value
 * @return {!proto.flow.Layer4} returns this
*/
proto.flow.Layer4.prototype.setTcp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.flow.Layer4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer4} returns this
 */
proto.flow.Layer4.prototype.clearTcp = function() {
  return this.setTcp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer4.prototype.hasTcp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UDP UDP = 2;
 * @return {?proto.flow.UDP}
 */
proto.flow.Layer4.prototype.getUdp = function() {
  return /** @type{?proto.flow.UDP} */ (
    jspb.Message.getWrapperField(this, proto.flow.UDP, 2));
};


/**
 * @param {?proto.flow.UDP|undefined} value
 * @return {!proto.flow.Layer4} returns this
*/
proto.flow.Layer4.prototype.setUdp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.flow.Layer4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer4} returns this
 */
proto.flow.Layer4.prototype.clearUdp = function() {
  return this.setUdp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer4.prototype.hasUdp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ICMPv4 ICMPv4 = 3;
 * @return {?proto.flow.ICMPv4}
 */
proto.flow.Layer4.prototype.getIcmpv4 = function() {
  return /** @type{?proto.flow.ICMPv4} */ (
    jspb.Message.getWrapperField(this, proto.flow.ICMPv4, 3));
};


/**
 * @param {?proto.flow.ICMPv4|undefined} value
 * @return {!proto.flow.Layer4} returns this
*/
proto.flow.Layer4.prototype.setIcmpv4 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.flow.Layer4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer4} returns this
 */
proto.flow.Layer4.prototype.clearIcmpv4 = function() {
  return this.setIcmpv4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer4.prototype.hasIcmpv4 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ICMPv6 ICMPv6 = 4;
 * @return {?proto.flow.ICMPv6}
 */
proto.flow.Layer4.prototype.getIcmpv6 = function() {
  return /** @type{?proto.flow.ICMPv6} */ (
    jspb.Message.getWrapperField(this, proto.flow.ICMPv6, 4));
};


/**
 * @param {?proto.flow.ICMPv6|undefined} value
 * @return {!proto.flow.Layer4} returns this
*/
proto.flow.Layer4.prototype.setIcmpv6 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.flow.Layer4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer4} returns this
 */
proto.flow.Layer4.prototype.clearIcmpv6 = function() {
  return this.setIcmpv6(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer4.prototype.hasIcmpv6 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SCTP SCTP = 5;
 * @return {?proto.flow.SCTP}
 */
proto.flow.Layer4.prototype.getSctp = function() {
  return /** @type{?proto.flow.SCTP} */ (
    jspb.Message.getWrapperField(this, proto.flow.SCTP, 5));
};


/**
 * @param {?proto.flow.SCTP|undefined} value
 * @return {!proto.flow.Layer4} returns this
*/
proto.flow.Layer4.prototype.setSctp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.flow.Layer4.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer4} returns this
 */
proto.flow.Layer4.prototype.clearSctp = function() {
  return this.setSctp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer4.prototype.hasSctp = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.flow.Layer7.oneofGroups_ = [[100,101,102]];

/**
 * @enum {number}
 */
proto.flow.Layer7.RecordCase = {
  RECORD_NOT_SET: 0,
  DNS: 100,
  HTTP: 101,
  KAFKA: 102
};

/**
 * @return {proto.flow.Layer7.RecordCase}
 */
proto.flow.Layer7.prototype.getRecordCase = function() {
  return /** @type {proto.flow.Layer7.RecordCase} */(jspb.Message.computeOneofCase(this, proto.flow.Layer7.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Layer7.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Layer7.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Layer7} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Layer7.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    latencyNs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dns: (f = msg.getDns()) && proto.flow.DNS.toObject(includeInstance, f),
    http: (f = msg.getHttp()) && proto.flow.HTTP.toObject(includeInstance, f),
    kafka: (f = msg.getKafka()) && proto.flow.Kafka.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Layer7}
 */
proto.flow.Layer7.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Layer7;
  return proto.flow.Layer7.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Layer7} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Layer7}
 */
proto.flow.Layer7.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flow.L7FlowType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLatencyNs(value);
      break;
    case 100:
      var value = new proto.flow.DNS;
      reader.readMessage(value,proto.flow.DNS.deserializeBinaryFromReader);
      msg.setDns(value);
      break;
    case 101:
      var value = new proto.flow.HTTP;
      reader.readMessage(value,proto.flow.HTTP.deserializeBinaryFromReader);
      msg.setHttp(value);
      break;
    case 102:
      var value = new proto.flow.Kafka;
      reader.readMessage(value,proto.flow.Kafka.deserializeBinaryFromReader);
      msg.setKafka(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Layer7.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Layer7.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Layer7} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Layer7.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLatencyNs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDns();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.flow.DNS.serializeBinaryToWriter
    );
  }
  f = message.getHttp();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.flow.HTTP.serializeBinaryToWriter
    );
  }
  f = message.getKafka();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.flow.Kafka.serializeBinaryToWriter
    );
  }
};


/**
 * optional L7FlowType type = 1;
 * @return {!proto.flow.L7FlowType}
 */
proto.flow.Layer7.prototype.getType = function() {
  return /** @type {!proto.flow.L7FlowType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flow.L7FlowType} value
 * @return {!proto.flow.Layer7} returns this
 */
proto.flow.Layer7.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 latency_ns = 2;
 * @return {number}
 */
proto.flow.Layer7.prototype.getLatencyNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Layer7} returns this
 */
proto.flow.Layer7.prototype.setLatencyNs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DNS dns = 100;
 * @return {?proto.flow.DNS}
 */
proto.flow.Layer7.prototype.getDns = function() {
  return /** @type{?proto.flow.DNS} */ (
    jspb.Message.getWrapperField(this, proto.flow.DNS, 100));
};


/**
 * @param {?proto.flow.DNS|undefined} value
 * @return {!proto.flow.Layer7} returns this
*/
proto.flow.Layer7.prototype.setDns = function(value) {
  return jspb.Message.setOneofWrapperField(this, 100, proto.flow.Layer7.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer7} returns this
 */
proto.flow.Layer7.prototype.clearDns = function() {
  return this.setDns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer7.prototype.hasDns = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional HTTP http = 101;
 * @return {?proto.flow.HTTP}
 */
proto.flow.Layer7.prototype.getHttp = function() {
  return /** @type{?proto.flow.HTTP} */ (
    jspb.Message.getWrapperField(this, proto.flow.HTTP, 101));
};


/**
 * @param {?proto.flow.HTTP|undefined} value
 * @return {!proto.flow.Layer7} returns this
*/
proto.flow.Layer7.prototype.setHttp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 101, proto.flow.Layer7.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer7} returns this
 */
proto.flow.Layer7.prototype.clearHttp = function() {
  return this.setHttp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer7.prototype.hasHttp = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional Kafka kafka = 102;
 * @return {?proto.flow.Kafka}
 */
proto.flow.Layer7.prototype.getKafka = function() {
  return /** @type{?proto.flow.Kafka} */ (
    jspb.Message.getWrapperField(this, proto.flow.Kafka, 102));
};


/**
 * @param {?proto.flow.Kafka|undefined} value
 * @return {!proto.flow.Layer7} returns this
*/
proto.flow.Layer7.prototype.setKafka = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.flow.Layer7.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.Layer7} returns this
 */
proto.flow.Layer7.prototype.clearKafka = function() {
  return this.setKafka(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.Layer7.prototype.hasKafka = function() {
  return jspb.Message.getField(this, 102) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.TraceContext.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.TraceContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.TraceContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TraceContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: (f = msg.getParent()) && proto.flow.TraceParent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.TraceContext}
 */
proto.flow.TraceContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.TraceContext;
  return proto.flow.TraceContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.TraceContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.TraceContext}
 */
proto.flow.TraceContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flow.TraceParent;
      reader.readMessage(value,proto.flow.TraceParent.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.TraceContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.TraceContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.TraceContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TraceContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.flow.TraceParent.serializeBinaryToWriter
    );
  }
};


/**
 * optional TraceParent parent = 1;
 * @return {?proto.flow.TraceParent}
 */
proto.flow.TraceContext.prototype.getParent = function() {
  return /** @type{?proto.flow.TraceParent} */ (
    jspb.Message.getWrapperField(this, proto.flow.TraceParent, 1));
};


/**
 * @param {?proto.flow.TraceParent|undefined} value
 * @return {!proto.flow.TraceContext} returns this
*/
proto.flow.TraceContext.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.TraceContext} returns this
 */
proto.flow.TraceContext.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.TraceContext.prototype.hasParent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.TraceParent.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.TraceParent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.TraceParent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TraceParent.toObject = function(includeInstance, msg) {
  var f, obj = {
    traceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.TraceParent}
 */
proto.flow.TraceParent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.TraceParent;
  return proto.flow.TraceParent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.TraceParent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.TraceParent}
 */
proto.flow.TraceParent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.TraceParent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.TraceParent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.TraceParent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TraceParent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTraceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string trace_id = 1;
 * @return {string}
 */
proto.flow.TraceParent.prototype.getTraceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.TraceParent} returns this
 */
proto.flow.TraceParent.prototype.setTraceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.Endpoint.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Endpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Endpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Endpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Endpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    identity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    namespace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    podName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    workloadsList: jspb.Message.toObjectList(msg.getWorkloadsList(),
    proto.flow.Workload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Endpoint}
 */
proto.flow.Endpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Endpoint;
  return proto.flow.Endpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Endpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Endpoint}
 */
proto.flow.Endpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdentity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodName(value);
      break;
    case 6:
      var value = new proto.flow.Workload;
      reader.readMessage(value,proto.flow.Workload.deserializeBinaryFromReader);
      msg.addWorkloads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Endpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Endpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Endpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Endpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPodName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWorkloadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.flow.Workload.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 ID = 1;
 * @return {number}
 */
proto.flow.Endpoint.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 identity = 2;
 * @return {number}
 */
proto.flow.Endpoint.prototype.getIdentity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string namespace = 3;
 * @return {string}
 */
proto.flow.Endpoint.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string labels = 4;
 * @return {!Array<string>}
 */
proto.flow.Endpoint.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string pod_name = 5;
 * @return {string}
 */
proto.flow.Endpoint.prototype.getPodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.setPodName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Workload workloads = 6;
 * @return {!Array<!proto.flow.Workload>}
 */
proto.flow.Endpoint.prototype.getWorkloadsList = function() {
  return /** @type{!Array<!proto.flow.Workload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.Workload, 6));
};


/**
 * @param {!Array<!proto.flow.Workload>} value
 * @return {!proto.flow.Endpoint} returns this
*/
proto.flow.Endpoint.prototype.setWorkloadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.flow.Workload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.Workload}
 */
proto.flow.Endpoint.prototype.addWorkloads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.flow.Workload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.Endpoint} returns this
 */
proto.flow.Endpoint.prototype.clearWorkloadsList = function() {
  return this.setWorkloadsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Workload.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Workload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Workload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Workload.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Workload}
 */
proto.flow.Workload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Workload;
  return proto.flow.Workload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Workload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Workload}
 */
proto.flow.Workload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Workload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Workload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Workload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Workload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.flow.Workload.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Workload} returns this
 */
proto.flow.Workload.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kind = 2;
 * @return {string}
 */
proto.flow.Workload.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Workload} returns this
 */
proto.flow.Workload.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.TCP.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.TCP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.TCP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TCP.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcePort: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destinationPort: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flags: (f = msg.getFlags()) && proto.flow.TCPFlags.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.TCP}
 */
proto.flow.TCP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.TCP;
  return proto.flow.TCP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.TCP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.TCP}
 */
proto.flow.TCP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourcePort(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDestinationPort(value);
      break;
    case 3:
      var value = new proto.flow.TCPFlags;
      reader.readMessage(value,proto.flow.TCPFlags.deserializeBinaryFromReader);
      msg.setFlags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.TCP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.TCP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.TCP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TCP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcePort();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDestinationPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFlags();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.flow.TCPFlags.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 source_port = 1;
 * @return {number}
 */
proto.flow.TCP.prototype.getSourcePort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.TCP} returns this
 */
proto.flow.TCP.prototype.setSourcePort = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 destination_port = 2;
 * @return {number}
 */
proto.flow.TCP.prototype.getDestinationPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.TCP} returns this
 */
proto.flow.TCP.prototype.setDestinationPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TCPFlags flags = 3;
 * @return {?proto.flow.TCPFlags}
 */
proto.flow.TCP.prototype.getFlags = function() {
  return /** @type{?proto.flow.TCPFlags} */ (
    jspb.Message.getWrapperField(this, proto.flow.TCPFlags, 3));
};


/**
 * @param {?proto.flow.TCPFlags|undefined} value
 * @return {!proto.flow.TCP} returns this
*/
proto.flow.TCP.prototype.setFlags = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.TCP} returns this
 */
proto.flow.TCP.prototype.clearFlags = function() {
  return this.setFlags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.TCP.prototype.hasFlags = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.IP.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.IP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.IP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.IP.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destination: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipversion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    encrypted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.IP}
 */
proto.flow.IP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.IP;
  return proto.flow.IP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.IP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.IP}
 */
proto.flow.IP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    case 3:
      var value = /** @type {!proto.flow.IPVersion} */ (reader.readEnum());
      msg.setIpversion(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEncrypted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.IP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.IP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.IP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.IP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpversion();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEncrypted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.flow.IP.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IP} returns this
 */
proto.flow.IP.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination = 2;
 * @return {string}
 */
proto.flow.IP.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IP} returns this
 */
proto.flow.IP.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IPVersion ipVersion = 3;
 * @return {!proto.flow.IPVersion}
 */
proto.flow.IP.prototype.getIpversion = function() {
  return /** @type {!proto.flow.IPVersion} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.flow.IPVersion} value
 * @return {!proto.flow.IP} returns this
 */
proto.flow.IP.prototype.setIpversion = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool encrypted = 4;
 * @return {boolean}
 */
proto.flow.IP.prototype.getEncrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.IP} returns this
 */
proto.flow.IP.prototype.setEncrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Ethernet.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Ethernet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Ethernet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Ethernet.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destination: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Ethernet}
 */
proto.flow.Ethernet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Ethernet;
  return proto.flow.Ethernet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Ethernet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Ethernet}
 */
proto.flow.Ethernet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Ethernet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Ethernet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Ethernet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Ethernet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.flow.Ethernet.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Ethernet} returns this
 */
proto.flow.Ethernet.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination = 2;
 * @return {string}
 */
proto.flow.Ethernet.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Ethernet} returns this
 */
proto.flow.Ethernet.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.TCPFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.TCPFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.TCPFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TCPFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    fin: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    syn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    rst: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    psh: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ack: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    urg: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ece: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    cwr: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    ns: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.TCPFlags}
 */
proto.flow.TCPFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.TCPFlags;
  return proto.flow.TCPFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.TCPFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.TCPFlags}
 */
proto.flow.TCPFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFin(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyn(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRst(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPsh(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAck(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUrg(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEce(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCwr(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.TCPFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.TCPFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.TCPFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TCPFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFin();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSyn();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRst();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPsh();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAck();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUrg();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getEce();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCwr();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getNs();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional bool FIN = 1;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getFin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setFin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool SYN = 2;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getSyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setSyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool RST = 3;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getRst = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setRst = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool PSH = 4;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getPsh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setPsh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool ACK = 5;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getAck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setAck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool URG = 6;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getUrg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setUrg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ECE = 7;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getEce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setEce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool CWR = 8;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getCwr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setCwr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool NS = 9;
 * @return {boolean}
 */
proto.flow.TCPFlags.prototype.getNs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.TCPFlags} returns this
 */
proto.flow.TCPFlags.prototype.setNs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.UDP.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.UDP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.UDP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.UDP.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcePort: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destinationPort: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.UDP}
 */
proto.flow.UDP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.UDP;
  return proto.flow.UDP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.UDP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.UDP}
 */
proto.flow.UDP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourcePort(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDestinationPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.UDP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.UDP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.UDP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.UDP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcePort();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDestinationPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 source_port = 1;
 * @return {number}
 */
proto.flow.UDP.prototype.getSourcePort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.UDP} returns this
 */
proto.flow.UDP.prototype.setSourcePort = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 destination_port = 2;
 * @return {number}
 */
proto.flow.UDP.prototype.getDestinationPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.UDP} returns this
 */
proto.flow.UDP.prototype.setDestinationPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.SCTP.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.SCTP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.SCTP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.SCTP.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcePort: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destinationPort: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.SCTP}
 */
proto.flow.SCTP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.SCTP;
  return proto.flow.SCTP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.SCTP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.SCTP}
 */
proto.flow.SCTP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourcePort(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDestinationPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.SCTP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.SCTP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.SCTP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.SCTP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcePort();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDestinationPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 source_port = 1;
 * @return {number}
 */
proto.flow.SCTP.prototype.getSourcePort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.SCTP} returns this
 */
proto.flow.SCTP.prototype.setSourcePort = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 destination_port = 2;
 * @return {number}
 */
proto.flow.SCTP.prototype.getDestinationPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.SCTP} returns this
 */
proto.flow.SCTP.prototype.setDestinationPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.ICMPv4.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.ICMPv4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.ICMPv4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ICMPv4.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.ICMPv4}
 */
proto.flow.ICMPv4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.ICMPv4;
  return proto.flow.ICMPv4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.ICMPv4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.ICMPv4}
 */
proto.flow.ICMPv4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.ICMPv4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.ICMPv4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.ICMPv4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ICMPv4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 type = 1;
 * @return {number}
 */
proto.flow.ICMPv4.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ICMPv4} returns this
 */
proto.flow.ICMPv4.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 code = 2;
 * @return {number}
 */
proto.flow.ICMPv4.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ICMPv4} returns this
 */
proto.flow.ICMPv4.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.ICMPv6.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.ICMPv6.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.ICMPv6} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ICMPv6.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.ICMPv6}
 */
proto.flow.ICMPv6.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.ICMPv6;
  return proto.flow.ICMPv6.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.ICMPv6} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.ICMPv6}
 */
proto.flow.ICMPv6.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.ICMPv6.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.ICMPv6.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.ICMPv6} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ICMPv6.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 type = 1;
 * @return {number}
 */
proto.flow.ICMPv6.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ICMPv6} returns this
 */
proto.flow.ICMPv6.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 code = 2;
 * @return {number}
 */
proto.flow.ICMPv6.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ICMPv6} returns this
 */
proto.flow.ICMPv6.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.EventTypeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.EventTypeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.EventTypeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EventTypeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    matchSubType: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    subType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.EventTypeFilter}
 */
proto.flow.EventTypeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.EventTypeFilter;
  return proto.flow.EventTypeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.EventTypeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.EventTypeFilter}
 */
proto.flow.EventTypeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMatchSubType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.EventTypeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.EventTypeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.EventTypeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EventTypeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMatchSubType();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSubType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.flow.EventTypeFilter.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.EventTypeFilter} returns this
 */
proto.flow.EventTypeFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool match_sub_type = 2;
 * @return {boolean}
 */
proto.flow.EventTypeFilter.prototype.getMatchSubType = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flow.EventTypeFilter} returns this
 */
proto.flow.EventTypeFilter.prototype.setMatchSubType = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 sub_type = 3;
 * @return {number}
 */
proto.flow.EventTypeFilter.prototype.getSubType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.EventTypeFilter} returns this
 */
proto.flow.EventTypeFilter.prototype.setSubType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.CiliumEventType.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.CiliumEventType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.CiliumEventType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.CiliumEventType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.CiliumEventType}
 */
proto.flow.CiliumEventType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.CiliumEventType;
  return proto.flow.CiliumEventType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.CiliumEventType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.CiliumEventType}
 */
proto.flow.CiliumEventType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.CiliumEventType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.CiliumEventType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.CiliumEventType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.CiliumEventType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSubType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.flow.CiliumEventType.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.CiliumEventType} returns this
 */
proto.flow.CiliumEventType.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sub_type = 2;
 * @return {number}
 */
proto.flow.CiliumEventType.prototype.getSubType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.CiliumEventType} returns this
 */
proto.flow.CiliumEventType.prototype.setSubType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.FlowFilter.repeatedFields_ = [1,2,7,10,16,26,3,4,8,11,17,27,5,6,9,12,13,14,15,18,19,20,21,22,23,24,25,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.FlowFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.FlowFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.FlowFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.FlowFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceIpList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    sourcePodList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sourceFqdnList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    sourceLabelList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    sourceServiceList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    sourceWorkloadList: jspb.Message.toObjectList(msg.getSourceWorkloadList(),
    proto.flow.Workload.toObject, includeInstance),
    destinationIpList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    destinationPodList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    destinationFqdnList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    destinationLabelList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    destinationServiceList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    destinationWorkloadList: jspb.Message.toObjectList(msg.getDestinationWorkloadList(),
    proto.flow.Workload.toObject, includeInstance),
    verdictList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    eventTypeList: jspb.Message.toObjectList(msg.getEventTypeList(),
    proto.flow.EventTypeFilter.toObject, includeInstance),
    httpStatusCodeList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    protocolList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    sourcePortList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    destinationPortList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    replyList: (f = jspb.Message.getRepeatedBooleanField(msg, 15)) == null ? undefined : f,
    dnsQueryList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    sourceIdentityList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    destinationIdentityList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    httpMethodList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    httpPathList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    tcpFlagsList: jspb.Message.toObjectList(msg.getTcpFlagsList(),
    proto.flow.TCPFlags.toObject, includeInstance),
    nodeNameList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    ipVersionList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    traceIdList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.FlowFilter}
 */
proto.flow.FlowFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.FlowFilter;
  return proto.flow.FlowFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.FlowFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.FlowFilter}
 */
proto.flow.FlowFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourcePod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceFqdn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceLabel(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceService(value);
      break;
    case 26:
      var value = new proto.flow.Workload;
      reader.readMessage(value,proto.flow.Workload.deserializeBinaryFromReader);
      msg.addSourceWorkload(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationPod(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationFqdn(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationLabel(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationService(value);
      break;
    case 27:
      var value = new proto.flow.Workload;
      reader.readMessage(value,proto.flow.Workload.deserializeBinaryFromReader);
      msg.addDestinationWorkload(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.flow.Verdict>} */ (reader.readPackedEnum());
      msg.setVerdictList(value);
      break;
    case 6:
      var value = new proto.flow.EventTypeFilter;
      reader.readMessage(value,proto.flow.EventTypeFilter.deserializeBinaryFromReader);
      msg.addEventType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addHttpStatusCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtocol(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourcePort(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationPort(value);
      break;
    case 15:
      var value = /** @type {!Array<boolean>} */ (reader.readPackedBool());
      msg.setReplyList(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addDnsQuery(value);
      break;
    case 19:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setSourceIdentityList(value);
      break;
    case 20:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setDestinationIdentityList(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addHttpMethod(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addHttpPath(value);
      break;
    case 23:
      var value = new proto.flow.TCPFlags;
      reader.readMessage(value,proto.flow.TCPFlags.deserializeBinaryFromReader);
      msg.addTcpFlags(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addNodeName(value);
      break;
    case 25:
      var value = /** @type {!Array<!proto.flow.IPVersion>} */ (reader.readPackedEnum());
      msg.setIpVersionList(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.addTraceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.FlowFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.FlowFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.FlowFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.FlowFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceIpList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSourcePodList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSourceFqdnList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getSourceLabelList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSourceServiceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getSourceWorkloadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.flow.Workload.serializeBinaryToWriter
    );
  }
  f = message.getDestinationIpList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDestinationPodList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDestinationFqdnList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDestinationLabelList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getDestinationServiceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getDestinationWorkloadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.flow.Workload.serializeBinaryToWriter
    );
  }
  f = message.getVerdictList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getEventTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.flow.EventTypeFilter.serializeBinaryToWriter
    );
  }
  f = message.getHttpStatusCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getProtocolList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getSourcePortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getDestinationPortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getReplyList();
  if (f.length > 0) {
    writer.writePackedBool(
      15,
      f
    );
  }
  f = message.getDnsQueryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getSourceIdentityList();
  if (f.length > 0) {
    writer.writePackedUint32(
      19,
      f
    );
  }
  f = message.getDestinationIdentityList();
  if (f.length > 0) {
    writer.writePackedUint32(
      20,
      f
    );
  }
  f = message.getHttpMethodList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getHttpPathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getTcpFlagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.flow.TCPFlags.serializeBinaryToWriter
    );
  }
  f = message.getNodeNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getIpVersionList();
  if (f.length > 0) {
    writer.writePackedEnum(
      25,
      f
    );
  }
  f = message.getTraceIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      28,
      f
    );
  }
};


/**
 * repeated string source_ip = 1;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourceIpList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourceIpList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourceIp = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceIpList = function() {
  return this.setSourceIpList([]);
};


/**
 * repeated string source_pod = 2;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourcePodList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourcePodList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourcePod = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourcePodList = function() {
  return this.setSourcePodList([]);
};


/**
 * repeated string source_fqdn = 7;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourceFqdnList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourceFqdnList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourceFqdn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceFqdnList = function() {
  return this.setSourceFqdnList([]);
};


/**
 * repeated string source_label = 10;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourceLabelList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourceLabelList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourceLabel = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceLabelList = function() {
  return this.setSourceLabelList([]);
};


/**
 * repeated string source_service = 16;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourceServiceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourceServiceList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourceService = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceServiceList = function() {
  return this.setSourceServiceList([]);
};


/**
 * repeated Workload source_workload = 26;
 * @return {!Array<!proto.flow.Workload>}
 */
proto.flow.FlowFilter.prototype.getSourceWorkloadList = function() {
  return /** @type{!Array<!proto.flow.Workload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.Workload, 26));
};


/**
 * @param {!Array<!proto.flow.Workload>} value
 * @return {!proto.flow.FlowFilter} returns this
*/
proto.flow.FlowFilter.prototype.setSourceWorkloadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.flow.Workload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.Workload}
 */
proto.flow.FlowFilter.prototype.addSourceWorkload = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.flow.Workload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceWorkloadList = function() {
  return this.setSourceWorkloadList([]);
};


/**
 * repeated string destination_ip = 3;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationIpList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationIpList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationIp = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationIpList = function() {
  return this.setDestinationIpList([]);
};


/**
 * repeated string destination_pod = 4;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationPodList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationPodList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationPod = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationPodList = function() {
  return this.setDestinationPodList([]);
};


/**
 * repeated string destination_fqdn = 8;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationFqdnList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationFqdnList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationFqdn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationFqdnList = function() {
  return this.setDestinationFqdnList([]);
};


/**
 * repeated string destination_label = 11;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationLabelList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationLabelList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationLabel = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationLabelList = function() {
  return this.setDestinationLabelList([]);
};


/**
 * repeated string destination_service = 17;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationServiceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationServiceList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationService = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationServiceList = function() {
  return this.setDestinationServiceList([]);
};


/**
 * repeated Workload destination_workload = 27;
 * @return {!Array<!proto.flow.Workload>}
 */
proto.flow.FlowFilter.prototype.getDestinationWorkloadList = function() {
  return /** @type{!Array<!proto.flow.Workload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.Workload, 27));
};


/**
 * @param {!Array<!proto.flow.Workload>} value
 * @return {!proto.flow.FlowFilter} returns this
*/
proto.flow.FlowFilter.prototype.setDestinationWorkloadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.flow.Workload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.Workload}
 */
proto.flow.FlowFilter.prototype.addDestinationWorkload = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.flow.Workload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationWorkloadList = function() {
  return this.setDestinationWorkloadList([]);
};


/**
 * repeated Verdict verdict = 5;
 * @return {!Array<!proto.flow.Verdict>}
 */
proto.flow.FlowFilter.prototype.getVerdictList = function() {
  return /** @type {!Array<!proto.flow.Verdict>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.flow.Verdict>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setVerdictList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.flow.Verdict} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addVerdict = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearVerdictList = function() {
  return this.setVerdictList([]);
};


/**
 * repeated EventTypeFilter event_type = 6;
 * @return {!Array<!proto.flow.EventTypeFilter>}
 */
proto.flow.FlowFilter.prototype.getEventTypeList = function() {
  return /** @type{!Array<!proto.flow.EventTypeFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.EventTypeFilter, 6));
};


/**
 * @param {!Array<!proto.flow.EventTypeFilter>} value
 * @return {!proto.flow.FlowFilter} returns this
*/
proto.flow.FlowFilter.prototype.setEventTypeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.flow.EventTypeFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.EventTypeFilter}
 */
proto.flow.FlowFilter.prototype.addEventType = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.flow.EventTypeFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearEventTypeList = function() {
  return this.setEventTypeList([]);
};


/**
 * repeated string http_status_code = 9;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getHttpStatusCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setHttpStatusCodeList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addHttpStatusCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearHttpStatusCodeList = function() {
  return this.setHttpStatusCodeList([]);
};


/**
 * repeated string protocol = 12;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getProtocolList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setProtocolList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addProtocol = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearProtocolList = function() {
  return this.setProtocolList([]);
};


/**
 * repeated string source_port = 13;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getSourcePortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourcePortList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourcePort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourcePortList = function() {
  return this.setSourcePortList([]);
};


/**
 * repeated string destination_port = 14;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDestinationPortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationPortList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationPort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationPortList = function() {
  return this.setDestinationPortList([]);
};


/**
 * repeated bool reply = 15;
 * @return {!Array<boolean>}
 */
proto.flow.FlowFilter.prototype.getReplyList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 15));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setReplyList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addReply = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearReplyList = function() {
  return this.setReplyList([]);
};


/**
 * repeated string dns_query = 18;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getDnsQueryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDnsQueryList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDnsQuery = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDnsQueryList = function() {
  return this.setDnsQueryList([]);
};


/**
 * repeated uint32 source_identity = 19;
 * @return {!Array<number>}
 */
proto.flow.FlowFilter.prototype.getSourceIdentityList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setSourceIdentityList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addSourceIdentity = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearSourceIdentityList = function() {
  return this.setSourceIdentityList([]);
};


/**
 * repeated uint32 destination_identity = 20;
 * @return {!Array<number>}
 */
proto.flow.FlowFilter.prototype.getDestinationIdentityList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setDestinationIdentityList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addDestinationIdentity = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearDestinationIdentityList = function() {
  return this.setDestinationIdentityList([]);
};


/**
 * repeated string http_method = 21;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getHttpMethodList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setHttpMethodList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addHttpMethod = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearHttpMethodList = function() {
  return this.setHttpMethodList([]);
};


/**
 * repeated string http_path = 22;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getHttpPathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setHttpPathList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addHttpPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearHttpPathList = function() {
  return this.setHttpPathList([]);
};


/**
 * repeated TCPFlags tcp_flags = 23;
 * @return {!Array<!proto.flow.TCPFlags>}
 */
proto.flow.FlowFilter.prototype.getTcpFlagsList = function() {
  return /** @type{!Array<!proto.flow.TCPFlags>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.TCPFlags, 23));
};


/**
 * @param {!Array<!proto.flow.TCPFlags>} value
 * @return {!proto.flow.FlowFilter} returns this
*/
proto.flow.FlowFilter.prototype.setTcpFlagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.flow.TCPFlags=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.TCPFlags}
 */
proto.flow.FlowFilter.prototype.addTcpFlags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.flow.TCPFlags, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearTcpFlagsList = function() {
  return this.setTcpFlagsList([]);
};


/**
 * repeated string node_name = 24;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getNodeNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setNodeNameList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addNodeName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearNodeNameList = function() {
  return this.setNodeNameList([]);
};


/**
 * repeated IPVersion ip_version = 25;
 * @return {!Array<!proto.flow.IPVersion>}
 */
proto.flow.FlowFilter.prototype.getIpVersionList = function() {
  return /** @type {!Array<!proto.flow.IPVersion>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<!proto.flow.IPVersion>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setIpVersionList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {!proto.flow.IPVersion} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addIpVersion = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearIpVersionList = function() {
  return this.setIpVersionList([]);
};


/**
 * repeated string trace_id = 28;
 * @return {!Array<string>}
 */
proto.flow.FlowFilter.prototype.getTraceIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.setTraceIdList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.addTraceId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.FlowFilter} returns this
 */
proto.flow.FlowFilter.prototype.clearTraceIdList = function() {
  return this.setTraceIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.DNS.repeatedFields_ = [2,4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.DNS.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.DNS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.DNS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.DNS.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    ttl: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    observationSource: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rcode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    qtypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    rrtypesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.DNS}
 */
proto.flow.DNS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.DNS;
  return proto.flow.DNS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.DNS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.DNS}
 */
proto.flow.DNS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIps(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTtl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCnames(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservationSource(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addQtypes(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addRrtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.DNS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.DNS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.DNS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.DNS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTtl();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getObservationSource();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRcode();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getQtypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getRrtypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.flow.DNS.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string ips = 2;
 * @return {!Array<string>}
 */
proto.flow.DNS.prototype.getIpsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setIpsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.addIps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.clearIpsList = function() {
  return this.setIpsList([]);
};


/**
 * optional uint32 ttl = 3;
 * @return {number}
 */
proto.flow.DNS.prototype.getTtl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setTtl = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string cnames = 4;
 * @return {!Array<string>}
 */
proto.flow.DNS.prototype.getCnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setCnamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.addCnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.clearCnamesList = function() {
  return this.setCnamesList([]);
};


/**
 * optional string observation_source = 5;
 * @return {string}
 */
proto.flow.DNS.prototype.getObservationSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setObservationSource = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 rcode = 6;
 * @return {number}
 */
proto.flow.DNS.prototype.getRcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setRcode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string qtypes = 7;
 * @return {!Array<string>}
 */
proto.flow.DNS.prototype.getQtypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setQtypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.addQtypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.clearQtypesList = function() {
  return this.setQtypesList([]);
};


/**
 * repeated string rrtypes = 8;
 * @return {!Array<string>}
 */
proto.flow.DNS.prototype.getRrtypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.setRrtypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.addRrtypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.DNS} returns this
 */
proto.flow.DNS.prototype.clearRrtypesList = function() {
  return this.setRrtypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.HTTPHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.HTTPHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.HTTPHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.HTTPHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.HTTPHeader}
 */
proto.flow.HTTPHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.HTTPHeader;
  return proto.flow.HTTPHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.HTTPHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.HTTPHeader}
 */
proto.flow.HTTPHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.HTTPHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.HTTPHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.HTTPHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.HTTPHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.flow.HTTPHeader.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.HTTPHeader} returns this
 */
proto.flow.HTTPHeader.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.flow.HTTPHeader.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.HTTPHeader} returns this
 */
proto.flow.HTTPHeader.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.HTTP.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.HTTP.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.HTTP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.HTTP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.HTTP.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    protocol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.flow.HTTPHeader.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.HTTP}
 */
proto.flow.HTTP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.HTTP;
  return proto.flow.HTTP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.HTTP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.HTTP}
 */
proto.flow.HTTP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 5:
      var value = new proto.flow.HTTPHeader;
      reader.readMessage(value,proto.flow.HTTPHeader.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.HTTP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.HTTP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.HTTP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.HTTP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.flow.HTTPHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 code = 1;
 * @return {number}
 */
proto.flow.HTTP.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.HTTP} returns this
 */
proto.flow.HTTP.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.flow.HTTP.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.HTTP} returns this
 */
proto.flow.HTTP.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.flow.HTTP.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.HTTP} returns this
 */
proto.flow.HTTP.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string protocol = 4;
 * @return {string}
 */
proto.flow.HTTP.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.HTTP} returns this
 */
proto.flow.HTTP.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated HTTPHeader headers = 5;
 * @return {!Array<!proto.flow.HTTPHeader>}
 */
proto.flow.HTTP.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.flow.HTTPHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.HTTPHeader, 5));
};


/**
 * @param {!Array<!proto.flow.HTTPHeader>} value
 * @return {!proto.flow.HTTP} returns this
*/
proto.flow.HTTP.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.flow.HTTPHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.HTTPHeader}
 */
proto.flow.HTTP.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.flow.HTTPHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.HTTP} returns this
 */
proto.flow.HTTP.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Kafka.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Kafka.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Kafka} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Kafka.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apiVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    apiKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    correlationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    topic: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Kafka}
 */
proto.flow.Kafka.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Kafka;
  return proto.flow.Kafka.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Kafka} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Kafka}
 */
proto.flow.Kafka.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApiVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCorrelationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Kafka.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Kafka.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Kafka} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Kafka.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getApiVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCorrelationId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 error_code = 1;
 * @return {number}
 */
proto.flow.Kafka.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Kafka} returns this
 */
proto.flow.Kafka.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 api_version = 2;
 * @return {number}
 */
proto.flow.Kafka.prototype.getApiVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Kafka} returns this
 */
proto.flow.Kafka.prototype.setApiVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string api_key = 3;
 * @return {string}
 */
proto.flow.Kafka.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Kafka} returns this
 */
proto.flow.Kafka.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 correlation_id = 4;
 * @return {number}
 */
proto.flow.Kafka.prototype.getCorrelationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.Kafka} returns this
 */
proto.flow.Kafka.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string topic = 5;
 * @return {string}
 */
proto.flow.Kafka.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Kafka} returns this
 */
proto.flow.Kafka.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.Service}
 */
proto.flow.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.Service;
  return proto.flow.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.Service}
 */
proto.flow.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.flow.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Service} returns this
 */
proto.flow.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.flow.Service.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.Service} returns this
 */
proto.flow.Service.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.LostEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.LostEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.LostEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.LostEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numEventsLost: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cpu: (f = msg.getCpu()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.LostEvent}
 */
proto.flow.LostEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.LostEvent;
  return proto.flow.LostEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.LostEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.LostEvent}
 */
proto.flow.LostEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flow.LostEventSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumEventsLost(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setCpu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.LostEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.LostEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.LostEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.LostEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumEventsLost();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCpu();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional LostEventSource source = 1;
 * @return {!proto.flow.LostEventSource}
 */
proto.flow.LostEvent.prototype.getSource = function() {
  return /** @type {!proto.flow.LostEventSource} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flow.LostEventSource} value
 * @return {!proto.flow.LostEvent} returns this
 */
proto.flow.LostEvent.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 num_events_lost = 2;
 * @return {number}
 */
proto.flow.LostEvent.prototype.getNumEventsLost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.LostEvent} returns this
 */
proto.flow.LostEvent.prototype.setNumEventsLost = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value cpu = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.flow.LostEvent.prototype.getCpu = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.flow.LostEvent} returns this
*/
proto.flow.LostEvent.prototype.setCpu = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.LostEvent} returns this
 */
proto.flow.LostEvent.prototype.clearCpu = function() {
  return this.setCpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.LostEvent.prototype.hasCpu = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.flow.AgentEvent.oneofGroups_ = [[100,101,102,103,104,105,106,107]];

/**
 * @enum {number}
 */
proto.flow.AgentEvent.NotificationCase = {
  NOTIFICATION_NOT_SET: 0,
  UNKNOWN: 100,
  AGENT_START: 101,
  POLICY_UPDATE: 102,
  ENDPOINT_REGENERATE: 103,
  ENDPOINT_UPDATE: 104,
  IPCACHE_UPDATE: 105,
  SERVICE_UPSERT: 106,
  SERVICE_DELETE: 107
};

/**
 * @return {proto.flow.AgentEvent.NotificationCase}
 */
proto.flow.AgentEvent.prototype.getNotificationCase = function() {
  return /** @type {proto.flow.AgentEvent.NotificationCase} */(jspb.Message.computeOneofCase(this, proto.flow.AgentEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.AgentEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.AgentEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.AgentEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.AgentEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unknown: (f = msg.getUnknown()) && proto.flow.AgentEventUnknown.toObject(includeInstance, f),
    agentStart: (f = msg.getAgentStart()) && proto.flow.TimeNotification.toObject(includeInstance, f),
    policyUpdate: (f = msg.getPolicyUpdate()) && proto.flow.PolicyUpdateNotification.toObject(includeInstance, f),
    endpointRegenerate: (f = msg.getEndpointRegenerate()) && proto.flow.EndpointRegenNotification.toObject(includeInstance, f),
    endpointUpdate: (f = msg.getEndpointUpdate()) && proto.flow.EndpointUpdateNotification.toObject(includeInstance, f),
    ipcacheUpdate: (f = msg.getIpcacheUpdate()) && proto.flow.IPCacheNotification.toObject(includeInstance, f),
    serviceUpsert: (f = msg.getServiceUpsert()) && proto.flow.ServiceUpsertNotification.toObject(includeInstance, f),
    serviceDelete: (f = msg.getServiceDelete()) && proto.flow.ServiceDeleteNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.AgentEvent}
 */
proto.flow.AgentEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.AgentEvent;
  return proto.flow.AgentEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.AgentEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.AgentEvent}
 */
proto.flow.AgentEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flow.AgentEventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 100:
      var value = new proto.flow.AgentEventUnknown;
      reader.readMessage(value,proto.flow.AgentEventUnknown.deserializeBinaryFromReader);
      msg.setUnknown(value);
      break;
    case 101:
      var value = new proto.flow.TimeNotification;
      reader.readMessage(value,proto.flow.TimeNotification.deserializeBinaryFromReader);
      msg.setAgentStart(value);
      break;
    case 102:
      var value = new proto.flow.PolicyUpdateNotification;
      reader.readMessage(value,proto.flow.PolicyUpdateNotification.deserializeBinaryFromReader);
      msg.setPolicyUpdate(value);
      break;
    case 103:
      var value = new proto.flow.EndpointRegenNotification;
      reader.readMessage(value,proto.flow.EndpointRegenNotification.deserializeBinaryFromReader);
      msg.setEndpointRegenerate(value);
      break;
    case 104:
      var value = new proto.flow.EndpointUpdateNotification;
      reader.readMessage(value,proto.flow.EndpointUpdateNotification.deserializeBinaryFromReader);
      msg.setEndpointUpdate(value);
      break;
    case 105:
      var value = new proto.flow.IPCacheNotification;
      reader.readMessage(value,proto.flow.IPCacheNotification.deserializeBinaryFromReader);
      msg.setIpcacheUpdate(value);
      break;
    case 106:
      var value = new proto.flow.ServiceUpsertNotification;
      reader.readMessage(value,proto.flow.ServiceUpsertNotification.deserializeBinaryFromReader);
      msg.setServiceUpsert(value);
      break;
    case 107:
      var value = new proto.flow.ServiceDeleteNotification;
      reader.readMessage(value,proto.flow.ServiceDeleteNotification.deserializeBinaryFromReader);
      msg.setServiceDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.AgentEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.AgentEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.AgentEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.AgentEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUnknown();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.flow.AgentEventUnknown.serializeBinaryToWriter
    );
  }
  f = message.getAgentStart();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.flow.TimeNotification.serializeBinaryToWriter
    );
  }
  f = message.getPolicyUpdate();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.flow.PolicyUpdateNotification.serializeBinaryToWriter
    );
  }
  f = message.getEndpointRegenerate();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.flow.EndpointRegenNotification.serializeBinaryToWriter
    );
  }
  f = message.getEndpointUpdate();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      proto.flow.EndpointUpdateNotification.serializeBinaryToWriter
    );
  }
  f = message.getIpcacheUpdate();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      proto.flow.IPCacheNotification.serializeBinaryToWriter
    );
  }
  f = message.getServiceUpsert();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.flow.ServiceUpsertNotification.serializeBinaryToWriter
    );
  }
  f = message.getServiceDelete();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      proto.flow.ServiceDeleteNotification.serializeBinaryToWriter
    );
  }
};


/**
 * optional AgentEventType type = 1;
 * @return {!proto.flow.AgentEventType}
 */
proto.flow.AgentEvent.prototype.getType = function() {
  return /** @type {!proto.flow.AgentEventType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flow.AgentEventType} value
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AgentEventUnknown unknown = 100;
 * @return {?proto.flow.AgentEventUnknown}
 */
proto.flow.AgentEvent.prototype.getUnknown = function() {
  return /** @type{?proto.flow.AgentEventUnknown} */ (
    jspb.Message.getWrapperField(this, proto.flow.AgentEventUnknown, 100));
};


/**
 * @param {?proto.flow.AgentEventUnknown|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setUnknown = function(value) {
  return jspb.Message.setOneofWrapperField(this, 100, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearUnknown = function() {
  return this.setUnknown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasUnknown = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional TimeNotification agent_start = 101;
 * @return {?proto.flow.TimeNotification}
 */
proto.flow.AgentEvent.prototype.getAgentStart = function() {
  return /** @type{?proto.flow.TimeNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.TimeNotification, 101));
};


/**
 * @param {?proto.flow.TimeNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setAgentStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 101, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearAgentStart = function() {
  return this.setAgentStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasAgentStart = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional PolicyUpdateNotification policy_update = 102;
 * @return {?proto.flow.PolicyUpdateNotification}
 */
proto.flow.AgentEvent.prototype.getPolicyUpdate = function() {
  return /** @type{?proto.flow.PolicyUpdateNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.PolicyUpdateNotification, 102));
};


/**
 * @param {?proto.flow.PolicyUpdateNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setPolicyUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearPolicyUpdate = function() {
  return this.setPolicyUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasPolicyUpdate = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional EndpointRegenNotification endpoint_regenerate = 103;
 * @return {?proto.flow.EndpointRegenNotification}
 */
proto.flow.AgentEvent.prototype.getEndpointRegenerate = function() {
  return /** @type{?proto.flow.EndpointRegenNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.EndpointRegenNotification, 103));
};


/**
 * @param {?proto.flow.EndpointRegenNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setEndpointRegenerate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 103, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearEndpointRegenerate = function() {
  return this.setEndpointRegenerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasEndpointRegenerate = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional EndpointUpdateNotification endpoint_update = 104;
 * @return {?proto.flow.EndpointUpdateNotification}
 */
proto.flow.AgentEvent.prototype.getEndpointUpdate = function() {
  return /** @type{?proto.flow.EndpointUpdateNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.EndpointUpdateNotification, 104));
};


/**
 * @param {?proto.flow.EndpointUpdateNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setEndpointUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 104, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearEndpointUpdate = function() {
  return this.setEndpointUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasEndpointUpdate = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional IPCacheNotification ipcache_update = 105;
 * @return {?proto.flow.IPCacheNotification}
 */
proto.flow.AgentEvent.prototype.getIpcacheUpdate = function() {
  return /** @type{?proto.flow.IPCacheNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.IPCacheNotification, 105));
};


/**
 * @param {?proto.flow.IPCacheNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setIpcacheUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 105, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearIpcacheUpdate = function() {
  return this.setIpcacheUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasIpcacheUpdate = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional ServiceUpsertNotification service_upsert = 106;
 * @return {?proto.flow.ServiceUpsertNotification}
 */
proto.flow.AgentEvent.prototype.getServiceUpsert = function() {
  return /** @type{?proto.flow.ServiceUpsertNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.ServiceUpsertNotification, 106));
};


/**
 * @param {?proto.flow.ServiceUpsertNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setServiceUpsert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 106, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearServiceUpsert = function() {
  return this.setServiceUpsert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasServiceUpsert = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional ServiceDeleteNotification service_delete = 107;
 * @return {?proto.flow.ServiceDeleteNotification}
 */
proto.flow.AgentEvent.prototype.getServiceDelete = function() {
  return /** @type{?proto.flow.ServiceDeleteNotification} */ (
    jspb.Message.getWrapperField(this, proto.flow.ServiceDeleteNotification, 107));
};


/**
 * @param {?proto.flow.ServiceDeleteNotification|undefined} value
 * @return {!proto.flow.AgentEvent} returns this
*/
proto.flow.AgentEvent.prototype.setServiceDelete = function(value) {
  return jspb.Message.setOneofWrapperField(this, 107, proto.flow.AgentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.AgentEvent} returns this
 */
proto.flow.AgentEvent.prototype.clearServiceDelete = function() {
  return this.setServiceDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.AgentEvent.prototype.hasServiceDelete = function() {
  return jspb.Message.getField(this, 107) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.AgentEventUnknown.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.AgentEventUnknown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.AgentEventUnknown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.AgentEventUnknown.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notification: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.AgentEventUnknown}
 */
proto.flow.AgentEventUnknown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.AgentEventUnknown;
  return proto.flow.AgentEventUnknown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.AgentEventUnknown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.AgentEventUnknown}
 */
proto.flow.AgentEventUnknown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.AgentEventUnknown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.AgentEventUnknown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.AgentEventUnknown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.AgentEventUnknown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotification();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.flow.AgentEventUnknown.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.AgentEventUnknown} returns this
 */
proto.flow.AgentEventUnknown.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string notification = 2;
 * @return {string}
 */
proto.flow.AgentEventUnknown.prototype.getNotification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.AgentEventUnknown} returns this
 */
proto.flow.AgentEventUnknown.prototype.setNotification = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.TimeNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.TimeNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.TimeNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TimeNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.TimeNotification}
 */
proto.flow.TimeNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.TimeNotification;
  return proto.flow.TimeNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.TimeNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.TimeNotification}
 */
proto.flow.TimeNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.TimeNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.TimeNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.TimeNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.TimeNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.flow.TimeNotification.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.flow.TimeNotification} returns this
*/
proto.flow.TimeNotification.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.TimeNotification} returns this
 */
proto.flow.TimeNotification.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.TimeNotification.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.PolicyUpdateNotification.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.PolicyUpdateNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.PolicyUpdateNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.PolicyUpdateNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.PolicyUpdateNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    revision: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ruleCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.PolicyUpdateNotification}
 */
proto.flow.PolicyUpdateNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.PolicyUpdateNotification;
  return proto.flow.PolicyUpdateNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.PolicyUpdateNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.PolicyUpdateNotification}
 */
proto.flow.PolicyUpdateNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRevision(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRuleCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.PolicyUpdateNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.PolicyUpdateNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.PolicyUpdateNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.PolicyUpdateNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRuleCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated string labels = 1;
 * @return {!Array<string>}
 */
proto.flow.PolicyUpdateNotification.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.PolicyUpdateNotification} returns this
 */
proto.flow.PolicyUpdateNotification.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.PolicyUpdateNotification} returns this
 */
proto.flow.PolicyUpdateNotification.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.PolicyUpdateNotification} returns this
 */
proto.flow.PolicyUpdateNotification.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional uint64 revision = 2;
 * @return {number}
 */
proto.flow.PolicyUpdateNotification.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.PolicyUpdateNotification} returns this
 */
proto.flow.PolicyUpdateNotification.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rule_count = 3;
 * @return {number}
 */
proto.flow.PolicyUpdateNotification.prototype.getRuleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.PolicyUpdateNotification} returns this
 */
proto.flow.PolicyUpdateNotification.prototype.setRuleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.EndpointRegenNotification.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.EndpointRegenNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.EndpointRegenNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.EndpointRegenNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EndpointRegenNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    error: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.EndpointRegenNotification}
 */
proto.flow.EndpointRegenNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.EndpointRegenNotification;
  return proto.flow.EndpointRegenNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.EndpointRegenNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.EndpointRegenNotification}
 */
proto.flow.EndpointRegenNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.EndpointRegenNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.EndpointRegenNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.EndpointRegenNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EndpointRegenNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.flow.EndpointRegenNotification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.EndpointRegenNotification} returns this
 */
proto.flow.EndpointRegenNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.flow.EndpointRegenNotification.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.EndpointRegenNotification} returns this
 */
proto.flow.EndpointRegenNotification.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.EndpointRegenNotification} returns this
 */
proto.flow.EndpointRegenNotification.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.EndpointRegenNotification} returns this
 */
proto.flow.EndpointRegenNotification.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.flow.EndpointRegenNotification.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.EndpointRegenNotification} returns this
 */
proto.flow.EndpointRegenNotification.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.EndpointUpdateNotification.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.EndpointUpdateNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.EndpointUpdateNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.EndpointUpdateNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EndpointUpdateNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    podName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.EndpointUpdateNotification}
 */
proto.flow.EndpointUpdateNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.EndpointUpdateNotification;
  return proto.flow.EndpointUpdateNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.EndpointUpdateNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.EndpointUpdateNotification}
 */
proto.flow.EndpointUpdateNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.EndpointUpdateNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.EndpointUpdateNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.EndpointUpdateNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.EndpointUpdateNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPodName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.flow.EndpointUpdateNotification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string labels = 2;
 * @return {!Array<string>}
 */
proto.flow.EndpointUpdateNotification.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.flow.EndpointUpdateNotification.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pod_name = 4;
 * @return {string}
 */
proto.flow.EndpointUpdateNotification.prototype.getPodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.setPodName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string namespace = 5;
 * @return {string}
 */
proto.flow.EndpointUpdateNotification.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.EndpointUpdateNotification} returns this
 */
proto.flow.EndpointUpdateNotification.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.IPCacheNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.IPCacheNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.IPCacheNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.IPCacheNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    cidr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    oldIdentity: (f = msg.getOldIdentity()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    hostIp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oldHostIp: jspb.Message.getFieldWithDefault(msg, 5, ""),
    encryptKey: jspb.Message.getFieldWithDefault(msg, 6, 0),
    namespace: jspb.Message.getFieldWithDefault(msg, 7, ""),
    podName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.IPCacheNotification}
 */
proto.flow.IPCacheNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.IPCacheNotification;
  return proto.flow.IPCacheNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.IPCacheNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.IPCacheNotification}
 */
proto.flow.IPCacheNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCidr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdentity(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setOldIdentity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostIp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldHostIp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEncryptKey(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.IPCacheNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.IPCacheNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.IPCacheNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.IPCacheNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCidr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOldIdentity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getHostIp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOldHostIp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEncryptKey();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPodName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string cidr = 1;
 * @return {string}
 */
proto.flow.IPCacheNotification.prototype.getCidr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setCidr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 identity = 2;
 * @return {number}
 */
proto.flow.IPCacheNotification.prototype.getIdentity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.UInt32Value old_identity = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.flow.IPCacheNotification.prototype.getOldIdentity = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 3));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.flow.IPCacheNotification} returns this
*/
proto.flow.IPCacheNotification.prototype.setOldIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.clearOldIdentity = function() {
  return this.setOldIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.IPCacheNotification.prototype.hasOldIdentity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string host_ip = 4;
 * @return {string}
 */
proto.flow.IPCacheNotification.prototype.getHostIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setHostIp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string old_host_ip = 5;
 * @return {string}
 */
proto.flow.IPCacheNotification.prototype.getOldHostIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setOldHostIp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 encrypt_key = 6;
 * @return {number}
 */
proto.flow.IPCacheNotification.prototype.getEncryptKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setEncryptKey = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string namespace = 7;
 * @return {string}
 */
proto.flow.IPCacheNotification.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string pod_name = 8;
 * @return {string}
 */
proto.flow.IPCacheNotification.prototype.getPodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.IPCacheNotification} returns this
 */
proto.flow.IPCacheNotification.prototype.setPodName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.ServiceUpsertNotificationAddr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.ServiceUpsertNotificationAddr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceUpsertNotificationAddr.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.ServiceUpsertNotificationAddr}
 */
proto.flow.ServiceUpsertNotificationAddr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.ServiceUpsertNotificationAddr;
  return proto.flow.ServiceUpsertNotificationAddr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.ServiceUpsertNotificationAddr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.ServiceUpsertNotificationAddr}
 */
proto.flow.ServiceUpsertNotificationAddr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.ServiceUpsertNotificationAddr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.ServiceUpsertNotificationAddr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceUpsertNotificationAddr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotificationAddr} returns this
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 port = 2;
 * @return {number}
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ServiceUpsertNotificationAddr} returns this
 */
proto.flow.ServiceUpsertNotificationAddr.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.ServiceUpsertNotification.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.ServiceUpsertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.ServiceUpsertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.ServiceUpsertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceUpsertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frontendAddress: (f = msg.getFrontendAddress()) && proto.flow.ServiceUpsertNotificationAddr.toObject(includeInstance, f),
    backendAddressesList: jspb.Message.toObjectList(msg.getBackendAddressesList(),
    proto.flow.ServiceUpsertNotificationAddr.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    trafficPolicy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 7, ""),
    extTrafficPolicy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    intTrafficPolicy: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.ServiceUpsertNotification}
 */
proto.flow.ServiceUpsertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.ServiceUpsertNotification;
  return proto.flow.ServiceUpsertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.ServiceUpsertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.ServiceUpsertNotification}
 */
proto.flow.ServiceUpsertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.flow.ServiceUpsertNotificationAddr;
      reader.readMessage(value,proto.flow.ServiceUpsertNotificationAddr.deserializeBinaryFromReader);
      msg.setFrontendAddress(value);
      break;
    case 3:
      var value = new proto.flow.ServiceUpsertNotificationAddr;
      reader.readMessage(value,proto.flow.ServiceUpsertNotificationAddr.deserializeBinaryFromReader);
      msg.addBackendAddresses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrafficPolicy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtTrafficPolicy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntTrafficPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.ServiceUpsertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.ServiceUpsertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.ServiceUpsertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceUpsertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFrontendAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.flow.ServiceUpsertNotificationAddr.serializeBinaryToWriter
    );
  }
  f = message.getBackendAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.flow.ServiceUpsertNotificationAddr.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTrafficPolicy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExtTrafficPolicy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIntTrafficPolicy();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.flow.ServiceUpsertNotification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ServiceUpsertNotificationAddr frontend_address = 2;
 * @return {?proto.flow.ServiceUpsertNotificationAddr}
 */
proto.flow.ServiceUpsertNotification.prototype.getFrontendAddress = function() {
  return /** @type{?proto.flow.ServiceUpsertNotificationAddr} */ (
    jspb.Message.getWrapperField(this, proto.flow.ServiceUpsertNotificationAddr, 2));
};


/**
 * @param {?proto.flow.ServiceUpsertNotificationAddr|undefined} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
*/
proto.flow.ServiceUpsertNotification.prototype.setFrontendAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.clearFrontendAddress = function() {
  return this.setFrontendAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.ServiceUpsertNotification.prototype.hasFrontendAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ServiceUpsertNotificationAddr backend_addresses = 3;
 * @return {!Array<!proto.flow.ServiceUpsertNotificationAddr>}
 */
proto.flow.ServiceUpsertNotification.prototype.getBackendAddressesList = function() {
  return /** @type{!Array<!proto.flow.ServiceUpsertNotificationAddr>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.ServiceUpsertNotificationAddr, 3));
};


/**
 * @param {!Array<!proto.flow.ServiceUpsertNotificationAddr>} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
*/
proto.flow.ServiceUpsertNotification.prototype.setBackendAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.flow.ServiceUpsertNotificationAddr=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.ServiceUpsertNotificationAddr}
 */
proto.flow.ServiceUpsertNotification.prototype.addBackendAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.flow.ServiceUpsertNotificationAddr, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.clearBackendAddressesList = function() {
  return this.setBackendAddressesList([]);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string traffic_policy = 5;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getTrafficPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setTrafficPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string namespace = 7;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ext_traffic_policy = 8;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getExtTrafficPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setExtTrafficPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string int_traffic_policy = 9;
 * @return {string}
 */
proto.flow.ServiceUpsertNotification.prototype.getIntTrafficPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.ServiceUpsertNotification} returns this
 */
proto.flow.ServiceUpsertNotification.prototype.setIntTrafficPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.ServiceDeleteNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.ServiceDeleteNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.ServiceDeleteNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceDeleteNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.ServiceDeleteNotification}
 */
proto.flow.ServiceDeleteNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.ServiceDeleteNotification;
  return proto.flow.ServiceDeleteNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.ServiceDeleteNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.ServiceDeleteNotification}
 */
proto.flow.ServiceDeleteNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.ServiceDeleteNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.ServiceDeleteNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.ServiceDeleteNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.ServiceDeleteNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.flow.ServiceDeleteNotification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.ServiceDeleteNotification} returns this
 */
proto.flow.ServiceDeleteNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.NetworkInterface.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.NetworkInterface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.NetworkInterface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.NetworkInterface.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.NetworkInterface}
 */
proto.flow.NetworkInterface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.NetworkInterface;
  return proto.flow.NetworkInterface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.NetworkInterface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.NetworkInterface}
 */
proto.flow.NetworkInterface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.NetworkInterface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.NetworkInterface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.NetworkInterface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.NetworkInterface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.flow.NetworkInterface.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.NetworkInterface} returns this
 */
proto.flow.NetworkInterface.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.flow.NetworkInterface.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.NetworkInterface} returns this
 */
proto.flow.NetworkInterface.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.DebugEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.DebugEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.DebugEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.DebugEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: (f = msg.getSource()) && proto.flow.Endpoint.toObject(includeInstance, f),
    hash: (f = msg.getHash()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    arg1: (f = msg.getArg1()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    arg2: (f = msg.getArg2()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    arg3: (f = msg.getArg3()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cpu: (f = msg.getCpu()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.DebugEvent}
 */
proto.flow.DebugEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.DebugEvent;
  return proto.flow.DebugEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.DebugEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.DebugEvent}
 */
proto.flow.DebugEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flow.DebugEventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.flow.Endpoint;
      reader.readMessage(value,proto.flow.Endpoint.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setHash(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setArg1(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setArg2(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setArg3(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setCpu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.DebugEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.DebugEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.DebugEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.DebugEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.flow.Endpoint.serializeBinaryToWriter
    );
  }
  f = message.getHash();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getArg1();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getArg2();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getArg3();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCpu();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional DebugEventType type = 1;
 * @return {!proto.flow.DebugEventType}
 */
proto.flow.DebugEvent.prototype.getType = function() {
  return /** @type {!proto.flow.DebugEventType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flow.DebugEventType} value
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Endpoint source = 2;
 * @return {?proto.flow.Endpoint}
 */
proto.flow.DebugEvent.prototype.getSource = function() {
  return /** @type{?proto.flow.Endpoint} */ (
    jspb.Message.getWrapperField(this, proto.flow.Endpoint, 2));
};


/**
 * @param {?proto.flow.Endpoint|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.UInt32Value hash = 3;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.flow.DebugEvent.prototype.getHash = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 3));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setHash = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearHash = function() {
  return this.setHash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasHash = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.UInt32Value arg1 = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.flow.DebugEvent.prototype.getArg1 = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 4));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setArg1 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearArg1 = function() {
  return this.setArg1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasArg1 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.UInt32Value arg2 = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.flow.DebugEvent.prototype.getArg2 = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 5));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setArg2 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearArg2 = function() {
  return this.setArg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasArg2 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.UInt32Value arg3 = 6;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.flow.DebugEvent.prototype.getArg3 = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 6));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setArg3 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearArg3 = function() {
  return this.setArg3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasArg3 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string message = 7;
 * @return {string}
 */
proto.flow.DebugEvent.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value cpu = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.flow.DebugEvent.prototype.getCpu = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.flow.DebugEvent} returns this
*/
proto.flow.DebugEvent.prototype.setCpu = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.DebugEvent} returns this
 */
proto.flow.DebugEvent.prototype.clearCpu = function() {
  return this.setCpu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.DebugEvent.prototype.hasCpu = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * @enum {number}
 */
proto.flow.FlowType = {
  UNKNOWN_TYPE: 0,
  L3_L4: 1,
  L7: 2,
  SOCK: 3
};

/**
 * @enum {number}
 */
proto.flow.TraceObservationPoint = {
  UNKNOWN_POINT: 0,
  TO_PROXY: 1,
  TO_HOST: 2,
  TO_STACK: 3,
  TO_OVERLAY: 4,
  TO_ENDPOINT: 101,
  FROM_ENDPOINT: 5,
  FROM_PROXY: 6,
  FROM_HOST: 7,
  FROM_STACK: 8,
  FROM_OVERLAY: 9,
  FROM_NETWORK: 10,
  TO_NETWORK: 11
};

/**
 * @enum {number}
 */
proto.flow.L7FlowType = {
  UNKNOWN_L7_TYPE: 0,
  REQUEST: 1,
  RESPONSE: 2,
  SAMPLE: 3
};

/**
 * @enum {number}
 */
proto.flow.IPVersion = {
  IP_NOT_USED: 0,
  IPV4: 1,
  IPV6: 2
};

/**
 * @enum {number}
 */
proto.flow.Verdict = {
  VERDICT_UNKNOWN: 0,
  FORWARDED: 1,
  DROPPED: 2,
  ERROR: 3,
  AUDIT: 4,
  REDIRECTED: 5,
  TRACED: 6,
  TRANSLATED: 7
};

/**
 * @enum {number}
 */
proto.flow.DropReason = {
  DROP_REASON_UNKNOWN: 0,
  INVALID_SOURCE_MAC: 130,
  INVALID_DESTINATION_MAC: 131,
  INVALID_SOURCE_IP: 132,
  POLICY_DENIED: 133,
  INVALID_PACKET_DROPPED: 134,
  CT_TRUNCATED_OR_INVALID_HEADER: 135,
  CT_MISSING_TCP_ACK_FLAG: 136,
  CT_UNKNOWN_L4_PROTOCOL: 137,
  CT_CANNOT_CREATE_ENTRY_FROM_PACKET: 138,
  UNSUPPORTED_L3_PROTOCOL: 139,
  MISSED_TAIL_CALL: 140,
  ERROR_WRITING_TO_PACKET: 141,
  UNKNOWN_L4_PROTOCOL: 142,
  UNKNOWN_ICMPV4_CODE: 143,
  UNKNOWN_ICMPV4_TYPE: 144,
  UNKNOWN_ICMPV6_CODE: 145,
  UNKNOWN_ICMPV6_TYPE: 146,
  ERROR_RETRIEVING_TUNNEL_KEY: 147,
  ERROR_RETRIEVING_TUNNEL_OPTIONS: 148,
  INVALID_GENEVE_OPTION: 149,
  UNKNOWN_L3_TARGET_ADDRESS: 150,
  STALE_OR_UNROUTABLE_IP: 151,
  NO_MATCHING_LOCAL_CONTAINER_FOUND: 152,
  ERROR_WHILE_CORRECTING_L3_CHECKSUM: 153,
  ERROR_WHILE_CORRECTING_L4_CHECKSUM: 154,
  CT_MAP_INSERTION_FAILED: 155,
  INVALID_IPV6_EXTENSION_HEADER: 156,
  IP_FRAGMENTATION_NOT_SUPPORTED: 157,
  SERVICE_BACKEND_NOT_FOUND: 158,
  NO_TUNNEL_OR_ENCAPSULATION_ENDPOINT: 160,
  FAILED_TO_INSERT_INTO_PROXYMAP: 161,
  REACHED_EDT_RATE_LIMITING_DROP_HORIZON: 162,
  UNKNOWN_CONNECTION_TRACKING_STATE: 163,
  LOCAL_HOST_IS_UNREACHABLE: 164,
  NO_CONFIGURATION_AVAILABLE_TO_PERFORM_POLICY_DECISION: 165,
  UNSUPPORTED_L2_PROTOCOL: 166,
  NO_MAPPING_FOR_NAT_MASQUERADE: 167,
  UNSUPPORTED_PROTOCOL_FOR_NAT_MASQUERADE: 168,
  FIB_LOOKUP_FAILED: 169,
  ENCAPSULATION_TRAFFIC_IS_PROHIBITED: 170,
  INVALID_IDENTITY: 171,
  UNKNOWN_SENDER: 172,
  NAT_NOT_NEEDED: 173,
  IS_A_CLUSTERIP: 174,
  FIRST_LOGICAL_DATAGRAM_FRAGMENT_NOT_FOUND: 175,
  FORBIDDEN_ICMPV6_MESSAGE: 176,
  DENIED_BY_LB_SRC_RANGE_CHECK: 177,
  SOCKET_LOOKUP_FAILED: 178,
  SOCKET_ASSIGN_FAILED: 179,
  PROXY_REDIRECTION_NOT_SUPPORTED_FOR_PROTOCOL: 180,
  POLICY_DENY: 181,
  VLAN_FILTERED: 182,
  INVALID_VNI: 183,
  INVALID_TC_BUFFER: 184,
  NO_SID: 185,
  MISSING_SRV6_STATE: 186,
  NAT46: 187,
  NAT64: 188,
  AUTH_REQUIRED: 189
};

/**
 * @enum {number}
 */
proto.flow.TrafficDirection = {
  TRAFFIC_DIRECTION_UNKNOWN: 0,
  INGRESS: 1,
  EGRESS: 2
};

/**
 * @enum {number}
 */
proto.flow.DebugCapturePoint = {
  DBG_CAPTURE_POINT_UNKNOWN: 0,
  DBG_CAPTURE_DELIVERY: 4,
  DBG_CAPTURE_FROM_LB: 5,
  DBG_CAPTURE_AFTER_V46: 6,
  DBG_CAPTURE_AFTER_V64: 7,
  DBG_CAPTURE_PROXY_PRE: 8,
  DBG_CAPTURE_PROXY_POST: 9,
  DBG_CAPTURE_SNAT_PRE: 10,
  DBG_CAPTURE_SNAT_POST: 11
};

/**
 * @enum {number}
 */
proto.flow.EventType = {
  UNKNOWN: 0,
  EVENTSAMPLE: 9,
  RECORDLOST: 2
};

/**
 * @enum {number}
 */
proto.flow.LostEventSource = {
  UNKNOWN_LOST_EVENT_SOURCE: 0,
  PERF_EVENT_RING_BUFFER: 1,
  OBSERVER_EVENTS_QUEUE: 2,
  HUBBLE_RING_BUFFER: 3
};

/**
 * @enum {number}
 */
proto.flow.AgentEventType = {
  AGENT_EVENT_UNKNOWN: 0,
  AGENT_STARTED: 2,
  POLICY_UPDATED: 3,
  POLICY_DELETED: 4,
  ENDPOINT_REGENERATE_SUCCESS: 5,
  ENDPOINT_REGENERATE_FAILURE: 6,
  ENDPOINT_CREATED: 7,
  ENDPOINT_DELETED: 8,
  IPCACHE_UPSERTED: 9,
  IPCACHE_DELETED: 10,
  SERVICE_UPSERTED: 11,
  SERVICE_DELETED: 12
};

/**
 * @enum {number}
 */
proto.flow.SocketTranslationPoint = {
  SOCK_XLATE_POINT_UNKNOWN: 0,
  SOCK_XLATE_POINT_PRE_DIRECTION_FWD: 1,
  SOCK_XLATE_POINT_POST_DIRECTION_FWD: 2,
  SOCK_XLATE_POINT_PRE_DIRECTION_REV: 3,
  SOCK_XLATE_POINT_POST_DIRECTION_REV: 4
};

/**
 * @enum {number}
 */
proto.flow.DebugEventType = {
  DBG_EVENT_UNKNOWN: 0,
  DBG_GENERIC: 1,
  DBG_LOCAL_DELIVERY: 2,
  DBG_ENCAP: 3,
  DBG_LXC_FOUND: 4,
  DBG_POLICY_DENIED: 5,
  DBG_CT_LOOKUP: 6,
  DBG_CT_LOOKUP_REV: 7,
  DBG_CT_MATCH: 8,
  DBG_CT_CREATED: 9,
  DBG_CT_CREATED2: 10,
  DBG_ICMP6_HANDLE: 11,
  DBG_ICMP6_REQUEST: 12,
  DBG_ICMP6_NS: 13,
  DBG_ICMP6_TIME_EXCEEDED: 14,
  DBG_CT_VERDICT: 15,
  DBG_DECAP: 16,
  DBG_PORT_MAP: 17,
  DBG_ERROR_RET: 18,
  DBG_TO_HOST: 19,
  DBG_TO_STACK: 20,
  DBG_PKT_HASH: 21,
  DBG_LB6_LOOKUP_FRONTEND: 22,
  DBG_LB6_LOOKUP_FRONTEND_FAIL: 23,
  DBG_LB6_LOOKUP_BACKEND_SLOT: 24,
  DBG_LB6_LOOKUP_BACKEND_SLOT_SUCCESS: 25,
  DBG_LB6_LOOKUP_BACKEND_SLOT_V2_FAIL: 26,
  DBG_LB6_LOOKUP_BACKEND_FAIL: 27,
  DBG_LB6_REVERSE_NAT_LOOKUP: 28,
  DBG_LB6_REVERSE_NAT: 29,
  DBG_LB4_LOOKUP_FRONTEND: 30,
  DBG_LB4_LOOKUP_FRONTEND_FAIL: 31,
  DBG_LB4_LOOKUP_BACKEND_SLOT: 32,
  DBG_LB4_LOOKUP_BACKEND_SLOT_SUCCESS: 33,
  DBG_LB4_LOOKUP_BACKEND_SLOT_V2_FAIL: 34,
  DBG_LB4_LOOKUP_BACKEND_FAIL: 35,
  DBG_LB4_REVERSE_NAT_LOOKUP: 36,
  DBG_LB4_REVERSE_NAT: 37,
  DBG_LB4_LOOPBACK_SNAT: 38,
  DBG_LB4_LOOPBACK_SNAT_REV: 39,
  DBG_CT_LOOKUP4: 40,
  DBG_RR_BACKEND_SLOT_SEL: 41,
  DBG_REV_PROXY_LOOKUP: 42,
  DBG_REV_PROXY_FOUND: 43,
  DBG_REV_PROXY_UPDATE: 44,
  DBG_L4_POLICY: 45,
  DBG_NETDEV_IN_CLUSTER: 46,
  DBG_NETDEV_ENCAP4: 47,
  DBG_CT_LOOKUP4_1: 48,
  DBG_CT_LOOKUP4_2: 49,
  DBG_CT_CREATED4: 50,
  DBG_CT_LOOKUP6_1: 51,
  DBG_CT_LOOKUP6_2: 52,
  DBG_CT_CREATED6: 53,
  DBG_SKIP_PROXY: 54,
  DBG_L4_CREATE: 55,
  DBG_IP_ID_MAP_FAILED4: 56,
  DBG_IP_ID_MAP_FAILED6: 57,
  DBG_IP_ID_MAP_SUCCEED4: 58,
  DBG_IP_ID_MAP_SUCCEED6: 59,
  DBG_LB_STALE_CT: 60,
  DBG_INHERIT_IDENTITY: 61,
  DBG_SK_LOOKUP4: 62,
  DBG_SK_LOOKUP6: 63,
  DBG_SK_ASSIGN: 64
};

goog.object.extend(exports, proto.flow);

export const kubernetes = 'icons/logos/kubernetes-logo.svg';
export const world = 'icons/logos/world-logo.svg';
export const host = world;
export const mongodb = 'icons/logos/mongodb-logo.svg';
export const redis = 'icons/logos/redis-logo.svg';
export const rabbitmq = 'icons/logos/rabbitmq-logo.svg';
export const elasticsearch = 'icons/logos/elasticsearch-logo.svg';
export const mysql = 'icons/logos/mysql-logo.svg';
export const postgresql = 'icons/logos/postgresql-logo.svg';
export const zookeeper = 'icons/logos/zookeeper-logo.png';
export const grpc = 'icons/logos/grpc-logo.svg';
export const http = 'icons/logos/http-logo.svg';
export const cassandra = 'icons/logos/cassandra-logo.svg';
export const kafka = 'icons/logos/kafka-logo.svg';
export const influxdb = 'icons/logos/influxdb-logo.svg';
export const covalent = 'icons/logos/covalent-logo.svg';
export const couchdb = 'icons/logos/couchdb-logo.svg';
export const grpahql = 'icons/logos/graphql-logo.svg';
export const etcd = 'icons/logos/etcd-logo.svg';
export const memcached = 'icons/logos/memcached-logo.svg';
export const aws = 'icons/logos/aws-logo.svg';
export const prometheus = 'icons/logos/prometheus-logo.svg';
